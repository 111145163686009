<template>
  <Modal
    v-model="visible"
    fullscreen
    :closable="false"
    class-name="photoCfgModal"
  >
    <p slot="header">
      视频设备【{{videoObj.name}}】的抓拍策略配置
      <Icon 
        title="返回列表" 
        type="ios-undo" 
        style="color: #08DDDD; position: absolute;top: 12px;right: 20px;font-size: 25px;cursor: pointer"
        @click="$emit('pcModalClose')"
      />
    </p>
    <div class = "filterBar flexboxb" style="margin-top: 0">
      <div class = "flexboxs">
        <div class = "filterItem">
          <Select class = "selectMid" :value="filter.status" placeholder="选择策略状态" label-in-value @on-change="val => {filterChange('status', 'select', val)}" clearable>
            <Option :value="0">未启用</Option>
            <Option :value="1">已启用</Option>
            <Option :value="2">已停用</Option>
            <Option :value="3">已过期</Option>
          </Select>
        </div>
      </div>
      <div v-if="accessData.sceneVideoPolAdd">
        <span class = "btnMy" @click="ployNew">添加策略</span>
      </div>
    </div>
    <div class="photoCfgGrid">
      <Table :loading="listLoading"  border :columns="listCol" :data="listData" :height="getGridHeight()"></Table>
    </div>
    <div class="pageBar">
        <Page :total="page.total" :current="page.pageNo" :page-size ="page.pageSize" show-elevator show-total @on-change="pageChange"></Page>
    </div>
    <div slot="footer" style="padding: 0">
      <!-- <Button type="primary" @click="$emit('ajModalClose')">返回板卡列表</Button> -->
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'PhotoCfgModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    videoObj: {
      type: Object,
      default: function () {
        return {
          name: '',
          id: '',
        }
      }
    },
    farmId: {
      type: [Number, String],
      default: 0
    },
    sceneId: {
      type: [Number, String],
      default: 0
    },
  },
  data () {
    return {
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10,
      },
      listCol: [
        {
          title: '策略周期',
          key: 'cycleName',
          minWidth: 210,
          render: (m, {row}) => {
            let c = this.$createElement;
            let cycleButton = row.cycle === 5 ? c('InputNumber', {
              props: {
                value: row.cycleVal,
                min: 0,
                step: 1,
              },
              style: {
                width: '60px'
              },
              ref: 'ployCycleValRef',
              on: {
                'on-change': val => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      cycleVal: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        cycleVal: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.ployCycleValRef.$el.children[1].children[0].focus();
                  })
                }
              }
            }) : void 0;
            if (!row.isE) {
              return row.cycle === 5 ? 
                c('span', `间隔${row.cycleVal}天`) : 
                c('span', row.cycleName)
            }
            return c('div', {
              'class': {
                flexboxs: true,
                noPass: (row.cycle === 5 && row.cycleVal) || ((row.cycle && row.cycle !== 5) || row.cycle === 0) ? false : true,
              }
            }, [
              c('Select', {
                style: {
                  width: '120px',
                  marginRight: '10px',
                },
                props: {
                  labelInValue: true,
                  value: row.cycle,
                },
                on: {
                  'on-change': val => {
                    if (!row.id) {
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        cycle: val.value,
                        cycleName: val.label
                      });
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        this.listData[i].id === row.id && this.listData.splice(i, 1, {
                          ...this.listData[i],
                          cycle: val.value,
                          cycleName: val.label
                        })
                      }
                    }
                  }
                }
              }, this.selOption(this.cycleOptions, c)),
              cycleButton
            ])
          }
        },
        {
          title: '执行日期',
          key: 'ployDate',
          minWidth: 220,
          render: (c, {row}) => {
            if (!row.isE) return c('span', `${row.executiveTime}~${row.executiveEndTime}`);
            return c('DatePicker', {
              props: {
                value: row.ployDate,
                type: 'daterange',
                format: 'yyyy-MM-dd',
                placeholder: '选择日期'
              },
              'class': {
                noPass: row.executiveTime && row.executiveEndTime ? false : true,
              },
              on: {
                'on-change': val => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      executiveTime: val[0],
                      executiveEndTime: val[1],
                      ployDate: [...val],
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        executiveTime: val[0],
                        executiveEndTime: val[1],
                        ployDate: [...val],
                      })
                    }
                  }
                }
              }
            })
          }
        },
        {
          title: '策略时段',
          key: 'timelist',
          minWidth: 110,
          render: (c, {row}) => {
            if (!row.isE) {
              let timelist = row.timelist ? JSON.parse(row.timelist) : [];
              let showTime = timelist.map(item => (`${item.beginTime}~${item.endTime}`)).join('，');
              return c('span', showTime)
            }
            let tags = row.timeTagsData.map(item => {
              return c('Tag', {
                props: {
                  closable: true,
                  name: `${item[0]}~${item[1]}`,
                },
                on: {
                  'on-close': (e, name) => {
                    if (!row.id) {
                      let times = this.listData[0].timeTagsData.filter(item => {
                          return `${item[0]}~${item[1]}` !== name
                        })
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        timeTagsData: times,
                        timelist: times.map(item => ({
                          beginTime: item[0],
                          endTime: item[1]
                        }))
                      });
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        if (this.listData[i].id === row.id) {
                          let times = this.listData[0].timeTagsData.filter(item => {
                            return `${item[0]}~${item[1]}` !== name
                          })
                          this.listData.splice(i, 1, {
                            ...this.listData[i],
                            timeTagsData: times,
                            timelist: times.map(item => ({
                              beginTime: item[0],
                              endTime: item[1]
                            }))
                          })
                        }
                        
                      }
                    }
                  }
                }
              }, `${item[0]}~${item[1]}`,)
            });
            return c('div', {
              'class': {
                noPass: row.timelist && row.timelist.constructor.name === 'Array' && row.timelist.length > 0 ? false : true,
              },
              style: {
                width: '100%'
              }
            },[
              c('div', {
                'class': {
                  wraper: true,
                }
              }, [
                c('TimePicker', {
                  props: {
                    type: 'timerange',
                    placement: 'top-end',
                    confirm: true,
                    open: row.isTimeOpen,
                    value: row.timer,
                    format: 'HH:mm'
                  },
                  on: {
                    'on-change': val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          timer: [...val]
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            timer: [...val]
                          })
                        }
                      }
                    },
                    'on-ok': () => {
                      let isRe = row.timeTagsData.filter(item => {
                        return `${item[0]}~${item[1]}` === `${row.timer[0]}~${row.timer[1]}`
                      }).length > 0;
                      if (!row.id) {
                        if (isRe || !row.timer[0]) {
                          this.listData.splice(0, 1, {
                            ...this.listData[0],
                            isTimeOpen: !this.listData[0].isTimeOpen,
                          });
                        } else {
                          let times = [...row.timeTagsData, row.timer];
                          this.listData.splice(0, 1, {
                            ...this.listData[0],
                            isTimeOpen: !this.listData[0].isTimeOpen,
                            timeTagsData: times,
                            timelist: times.map(item => ({
                              beginTime: item[0],
                              endTime: item[1]
                            }))
                          });
                        }
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          if (this.listData[i].id === row.id) {
                            if (isRe || !row.timer[0]) {
                              this.listData.splice(i, 1, {
                                ...this.listData[i],
                                isTimeOpen: !this.listData[i].isTimeOpen,
                              })
                            } else {
                              let times = [...row.timeTagsData, row.timer];
                              this.listData.splice(i, 1, {
                                ...this.listData[i],
                                isTimeOpen: !this.listData[i].isTimeOpen,
                                timeTagsData: times,
                                timelist: times.map(item => ({
                                  beginTime: item[0],
                                  endTime: item[1]
                                }))
                              })
                            }
                          }
                        }
                      }
                    }
                  }
                }, [
                  c('a', {
                    on: {
                      click: e => {
                        if (!row.id) {
                          this.listData.splice(0, 1, {
                            ...this.listData[0],
                            isTimeOpen: !this.listData[0].isTimeOpen
                          });
                        } else {
                          for (var i = 0; i < this.listData.length; i++) {
                            this.listData[i].id === row.id && this.listData.splice(i, 1, {
                              ...this.listData[i],
                              isTimeOpen: !this.listData[i].isTimeOpen
                            })
                          }
                        }
                      }
                    }
                  }, [c('Icon', {
                    props: {
                      type: 'md-add',
                      color: '#08DDDD'
                    },
                    attrs: {
                      title: '添加时段'
                    }
                  })])
                ]),
                ...tags
              ])
            ])
          }
        },
        {
          title: '状态',
          key: 'statusName',
          minWidth: 110,
          render: (c, {row}) => {
            if (row.isE) return c('span', '--');
            if (row.status === 3) return c('span', {
              style: {
                color: '#e4393c'
              }
            }, row.statusName);
            return this.statusLoading && this.statusId === row.id ? c('Spin', {
              props: {
                size: 'small'
              }
            }) : c('Select', {
              props: {
                value: +row.status,
                size: 'small'
              },
              on: {
                'on-change': val => {this.statusChange(val, row.id)}
              }
            }, [
              c('Option', {
                props: {
                  value: 0
                }
              }, '未启用'),
              c('Option', {
                props: {
                  value: 1
                }
              }, '启用')
            ])
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 130,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) return c('span', row.remark);
            return c('Input', {
              props: {
                value: row.remark,
              },
              ref: 'polyRemarkInputRef',
              on: {
                'on-change': e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      remark: e.target.value,
                    })
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        remark: e.target.value
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.polyRemarkInputRef.focus();
                  })
                }
              }
            })
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 110,
          render: (c, {row}) => {
            if (row.isE) {
              return c('div', [
                c('Icon', {
                  attrs: {
                    title: '保存'
                  },
                  style: {
                    marginRight: '15px',
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-checkmark'
                  },
                  nativeOn: {
                    click: () => {
                      !row.id ? this.savePloyNew(row) : this.savePloyEdit(row);
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '取消'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-close'
                  },
                  nativeOn: {
                    click: () => {
                      !row.id ? this.cancelPloyNew(row) : this.cancelPloyEdit(row)
                    }
                  }
                }),
              ])
            } else {
              let delBtn = c('Icon', {
                attrs: {
                  title: '删除'
                },
                style: {
                  cursor: 'pointer',
                  color: '#08DDDD',
                  fontSize: '16px',
                },
                props: {
                  type: 'md-trash'
                }
              });
              return this.deleteLoading && this.deletingId === row.id ? c('Spin', {
                props: {
                  size: 'small'
                }
              }) : c('div', [
                this.accessData.sceneVideoPolMod && c('Icon', {
                  attrs: {
                    title: '编辑'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-brush'
                  },
                  nativeOn: {
                    click: () => {
                      this.ployEdit(row)
                    }
                  }
                }),
                this.accessData.sceneVideoPolDel && c('Poptip', {
                  props: {
                    confirm: true,
                    title: '确认删除？',
                    placement: 'left'
                  },
                  on: {
                    'on-ok': () => {
                      this.ployDel(row.id, row.guid)
                    },
                    'on-cancel': () => {

                    }
                  }
                }, [delBtn])
              ])
            }
          }
        }
      ],
      cycleOptions: [
        {
          label: '今天',
          value: 1
        },
        {
          label: '每天',
          value: 2
        },
        {
          label: '每周',
          value: 3
        },
        {
          label: '每月',
          value: 4
        },
        {
          label: '输入间隔周期',
          value: 5
        }
      ],
      listData: [],
      listLoading: false,
      filter: {},
      isTimeOpen: false,
      editData: {},
      deletingId: '',
      deleteLoading: false,
      statusId: '',
      statusLoading: false,
    }
  },
  computed: {
    accessData () {
      return {
        // 检测、控制、视频表格权限
        sceneVideoPolAdd: true, // this.$store.state.app.permissionsGroup.sceneVideoPolAdd,
        sceneVideoPolMod: true, // this.$store.state.app.permissionsGroup.sceneVideoPolMod,
        sceneVideoPolDel: true, // this.$store.state.app.permissionsGroup.sceneVideoPolDel,
      }
    }
  },

  methods: {
    getGridHeight () {
      return window.innerHeight - 188
    },

    filterChange (field, type, val, selData) {
      if (type === 'cas') {
        if (val.length === 1) {
          this.filter = {
            ...this.filter,
            farmId: val[0],
          }
        } else if (val.length === 2) {
          this.filter = {
            ...this.filter,
            farmId: val[0],
            sceneId: val[1],
          }
        } else {
          this.filter = {
            ...this.filter,
            farmId: void 0,
            sceneId: void 0,
          }
        }
        this.getList(true);
      } else if (type === 'input') {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.filter = {
            ...this.filter,
            [field]: val.target.value ? val.target.value : void 0,
          };
          this.getList(true)
        }, 500);
      } else if (type === 'select') {
        this.filter = {
            ...this.filter,
            [field]: val ? val.value : val,
        }
        this.getList(true);
      } else if (type === 'datetimerange') {
        this.filter={
          ...this.filter,
          beginTime: val[0] ? val[0] : void 0,
          endTime: val[1] ? val[1] : void 0,
        }
        this.getList(true);
      }
    },

    pageChange (pageNo) {
      this.page.pageNo = pageNo
      this.getList(true)
    },

    selOption (dataArr, c) {
      var options = [];
      for (var val of dataArr) {
        options.push(
          c('Option', {
            props: {
              value: val.value,
            }
          }, val.label)
        )
      }
      return options;
    },

    getList (loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_LIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter,
      }).then(resp => {
        this.listData = resp.list.map(item => {
          return {
            ...item,
            farmId: +item.farmId,
          }
        });
        this.page = {
          total: +resp.total,
          pageNo: +resp.pageNo,
          totalPage: +resp.totalPage,
          pageSize: +resp.pageSize,
        }
        loadingShow && (this.listLoading = false);
      }).catch(e => {
        loadingShow && (this.listLoading = false);
      })
    },

    ployNew () {
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
        timeTagsData: []
      })
    },

    savePloyNew (r) {
      let noPass = !((r.cycle === 5 && r.cycleVal) || ((r.cycle && r.cycle !== 5) || r.cycle === 0))
      || !(r.executiveTime && r.executiveEndTime)
      || !(r.timelist && r.timelist.constructor.name === 'Array' && r.timelist.length);
      if (noPass) return;
      this.listLoading = true;
      delete r.isE;
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_NEW, {
        ...r,
        timelist: JSON.stringify(r.timelist),
        recordVideoId: this.videoObj.id,
        farmId: this.farmId,
        sceneId: this.sceneId,
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })
    },

    cancelPloyNew () {
      this.listData.shift()
    }, 

    ployEdit (record) {
      let listData = this.listData.map(item => {
        return item.id === record.id ? {
          ...item, 
          timeTagsData: JSON.parse(item.timelist).map(elem => {
            return [elem.beginTime, elem.endTime]
          }), 
          timelist: JSON.parse(item.timelist),
          ployDate: [item.executiveTime, item.executiveEndTime],
          isE: true,
        } : item
      })
      this.listData = listData;
      this.editData = {...record}
    },

    savePloyEdit (r) {
      let noPass = !((r.cycle === 5 && r.cycleVal) || ((r.cycle && r.cycle !== 5) || r.cycle === 0))
      || !(r.executiveTime && r.executiveEndTime)
      || !(r.timelist && r.timelist.constructor.name === 'Array' && r.timelist.length);
      if (noPass) return;
      this.listLoading = true;
      delete r.isE;
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_EDIT, {
        ...r,
        timelist: JSON.stringify(r.timelist),
        recordVideoId: this.videoObj.id,
        farmId: this.farmId,
        sceneId: this.sceneId,
        id: r.id
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })
    },

    cancelPloyEdit (record) {
      this.listData = this.listData.map(item => {
        return item.id === record.id ? this.editData : item
      });
    },

    ployDel (id, guid) {
      this.deletingId = id;
      this.deleteLoading = true;
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_DEL, {
        id, guid,
      }).then(resp => {
        this.deleteLoading = false;
        this.getList(true)
      }).catch(e => {
        this.deleteLoading = false;
      })
    },

    statusChange (status, id) {
      this.statusLoading = true;
      this.statusId = id;
      this.$post(this.$api.RECORD.RECORD_PHOTO_PLOY_STATUS_CHANGE, {
        status,
        id,
      }).then(resp => {
        this.statusLoading = false;
        this.getList();
      }).catch(e => {
        this.statusLoading = false;
      })
    },

  },
  mounted () {
    // this.getList(true);
  },

  watch: {
    videoObj: {
      handler (newVal, oldVal) {
        if (newVal.id === oldVal.id) return
        this.filter={
          ...this.filter,
          farmId: this.farmId,
          sceneId: this.sceneId,
          recordVideoId: newVal.id
        }
        this.getList(true)
      },
      deep: true,
    }
  }
}
</script>
<style lang="less">
.photoCfgModal {
    .ivu-modal-footer {
      padding: 0;
    }
    .ivu-modal-body {
      @footer: 51px;
      height: calc(~"100vh - @{footer}");
    }
  .photoCfgGrid {
    // margin-top: 15px;
  }
  .noPass {
    input {
      border: 1px solid #e4393c;
    }
    .ivu-select-selection {
      border: 1px solid #e4393c;
    }
    .ivu-input-number {
      border: 1px solid #e4393c;
      input {
        border: none;
      }
    }
    .wraper {
      border: 1px solid #e4393c;
      width: 100%;
      min-height: 30px;
      border-radius: 4px;
      margin: 10px 0;
    }
  }
  .ivu-date-picker-cells-cell-selected em, .ivu-date-picker-cells-cell-selected:hover em {
    background: #08DDDD;
  }
  .ivu-date-picker-cells-focused em {
    box-shadow: 0 0 0 1px #08DDDD inset;
  }
  .ivu-date-picker-cells-cell-range:before {
    background: #e1fee5;
  }
  .ivu-date-picker-cells-cell-today em:after {
    background: #08DDDD;
  }
  .ivu-date-picker-cells-cell:hover em {
    background: #e1fee5;
  }
}
</style>


