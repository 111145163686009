<template>
  <div class="svideo">
    <Modal
      @on-visible-change="mapModalChange"
      title="基地定位"
      fullscreen
      v-model="mapModel"
      class="secModMapModel"
    >
      <div ref="mapContainer" style="width:100%;height:100%;overflow:auto">
        <div ref="mapInnerContainer" style="position:relative;width:2000px;height:1250px">
          <img @click="positionClick" style="width:2000px;height:1250px" src="/static/imgs/map.jpg" />
          <img
            src="../../../../assets/imgs/jiankong.svg"
            :style="{position:'absolute',left:positionX+'px',top:positionY+'px',width:'30px',height:'30px',transform:'translate(-50%,-50%)'}"
            v-if="positionSelected"
          />
        </div>
      </div>
      <div slot="footer" style="text-align:center">
        <Button type="primary" @click="confirmPosition">确定</Button>
      </div>
    </Modal>
    <photo-cfg-modal
      :visible="isPhotoCfgModalShow"
      v-on:pcModalClose="pcModalClose"
      :videoObj="currentVideoObj"
      :farmId="farmId"
      :sceneId="sceneId"
    />
    <div class="filterBar flexboxb">
      <div class="flexboxs">
        <div class="filterItem">
          <Cascader
            placeholder="选择基地位置"
            class="selectMid"
            change-on-select
            trigger="click"
            :load-data="getFarmChildren"
            :data="farmOptions"
            @on-change="(val, selData) => {filterChange('farm', 'cas', val, selData)}"
          />
        </div>
        <!-- <div class = "filterItem">
          <Input placeholder="输入监测点名称" @on-change="e => {this.filterChange('groupName', 'input', e)}"/>
        </div>
        <div class = "filterItem">
          <Input placeholder="输入监测点名称" @on-change="e => {this.filterChange('name', 'input', e)}"/>
        </div>-->
      </div>
      <div v-if="accessData.sceneVideoAdd">
        <span class="btnMy" @click="videoNew">添加视频配置</span>
      </div>
    </div>
    <div class="videoGrid">
      <Table border :columns="listCol" :data="listData" height="500"></Table>
    </div>
    <div class="pageBar">
      <Page
        :total="page.total"
        :current="page.pageNo"
        :page-size="page.pageSize"
        show-elevator
        show-total
        @on-change="pageChange"
      ></Page>
    </div>
  </div>
</template>
<script>
import PhotoCfgModal from "./photoCfgModal.vue";
export default {
  name: "Svideo",
  components: {
    PhotoCfgModal
  },
  props: {
    farmId: {
      type: Number,
      default: 0
    },
    sceneId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listCol: [
        {
          title: "设备名称",
          key: "name",
          minWidth: 120,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.name);
            return c("Input", {
              class: {
                noPass: row.name ? false : true
              },
              props: {
                value: row.name
              },
              ref: "videoNameInputRef",
              on: {
                "on-change": e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      name: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id &&
                        this.listData.splice(i, 1, {
                          ...this.listData[i],
                          name: e.target.value
                        });
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.videoNameInputRef.focus();
                  });
                }
              }
            });
          }
        },
        {
          title: "设备类型",
          key: "deviceTypeName",
          minWidth: 100,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.deviceTypeName);
            return c(
              "Select",
              {
                props: {
                  labelInValue: true,
                  value: row.deviceType
                },
                class: {
                  noPass: row.deviceType || row.deviceType === 0 ? false : true
                },
                on: {
                  "on-change": val => {
                    if (!row.id) {
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        deviceType: val.value,
                        deviceTypeName: val.label
                      });
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        this.listData[i].id === row.id &&
                          this.listData.splice(i, 1, {
                            ...this.listData[i],
                            deviceType: val.value,
                            deviceTypeName: val.label
                          });
                      }
                    }
                  }
                }
              },
              this.selOption(this.deviceTypeOptions, c)
            );
          }
        },
        {
          title: "IP/域名",
          key: "linkIp",
          minWidth: 120,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.linkIp);
            return c("Input", {
              class: {
                noPass: row.linkIp ? false : true
              },
              props: {
                value: row.linkIp
              },
              ref: "videoLinkIpInputRef",
              on: {
                "on-change": e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      linkIp: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id &&
                        this.listData.splice(i, 1, {
                          ...this.listData[i],
                          linkIp: e.target.value
                        });
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.videoLinkIpInputRef.focus();
                  });
                }
              }
            });
          }
        },
        {
          title: "端口号",
          key: "devicePort",
          minWidth: 100,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.devicePort);
            if (row.deviceType === 6) return <span>--</span>;
            return c(
              "div",
              {
                class: {
                  noPass: row.devicePort || +row.devicePort === 0 ? false : true
                }
              },
              [
                c("InputNumber", {
                  props: {
                    value: row.devicePort,
                    min: 0,
                    step: 1
                  },
                  ref: "videoDevicePortInputRef",
                  on: {
                    "on-change": val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          devicePort: val
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id &&
                            this.listData.splice(i, 1, {
                              ...this.listData[i],
                              devicePort: val
                            });
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.videoDevicePortInputRef.$el.children[1].children[0].focus();
                      });
                    }
                  }
                })
              ]
            );
          }
        },
        {
          title: "通道号",
          key: "channelNo",
          minWidth: 100,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.channelNo);
            if (row.deviceType === 6) return <span>--</span>;
            return c(
              "div",
              {
                class: {
                  noPass: row.channelNo || +row.channelNo === 0 ? false : true
                }
              },
              [
                c("InputNumber", {
                  props: {
                    value: row.channelNo,
                    min: 0,
                    step: 1
                  },
                  ref: "videochannelNoInputRef",
                  on: {
                    "on-change": val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          channelNo: val
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id &&
                            this.listData.splice(i, 1, {
                              ...this.listData[i],
                              channelNo: val
                            });
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.videochannelNoInputRef.$el.children[1].children[0].focus();
                      });
                    }
                  }
                })
              ]
            );
          }
        },
        {
          title: "设备登录名",
          key: "loginUser",
          minWidth: 110,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.loginUser);
            if (row.deviceType === 6) return <span>--</span>;
            return c("Input", {
              class: {
                noPass: row.loginUser ? false : true
              },
              props: {
                value: row.loginUser
              },
              ref: "videoLoginUserInputRef",
              on: {
                "on-change": e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      loginUser: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id &&
                        this.listData.splice(i, 1, {
                          ...this.listData[i],
                          loginUser: e.target.value
                        });
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.videoLoginUserInputRef.focus();
                  });
                }
              }
            });
          }
        },
        {
          title: "设备登录密码",
          key: "loginPwd",
          minWidth: 110,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.loginPwd);
            if (row.deviceType === 6) return <span>--</span>;
            return c("Input", {
              class: {
                noPass: row.loginPwd ? false : true
              },
              props: {
                value: row.loginPwd
              },
              ref: "videoLoginPwdInputRef",
              on: {
                "on-change": e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      loginPwd: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id &&
                        this.listData.splice(i, 1, {
                          ...this.listData[i],
                          loginPwd: e.target.value
                        });
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.videoLoginPwdInputRef.focus();
                  });
                }
              }
            });
          }
        },
        {
          title: "监控设备状态",
          key: "statusName",
          minWidth: 110,
          render: (c, { row }) => {
            if (row.isE) return c("span", "--");
            return this.statusLoading && this.statusId === row.id
              ? c("Spin", {
                  props: {
                    size: "small"
                  }
                })
              : this.accessData.sceneVideoStatus
              ? c(
                  "Select",
                  {
                    props: {
                      value: +row.status,
                      size: "small"
                    },
                    on: {
                      "on-change": val => {
                        this.statusChange(val, row.id);
                      }
                    }
                  },
                  [
                    c(
                      "Option",
                      {
                        props: {
                          value: 0
                        }
                      },
                      "未启用"
                    ),
                    c(
                      "Option",
                      {
                        props: {
                          value: 1
                        }
                      },
                      "启用"
                    ),
                    c(
                      "Option",
                      {
                        props: {
                          value: 2
                        }
                      },
                      "停用"
                    )
                  ]
                )
              : c("span", row.statusName);
          }
        },
        {
          title: "抓拍分辨率",
          key: "photoTypeName",
          minWidth: 110,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.photoTypeName);
            if (row.deviceType === 6) return <span>--</span>;
            return c(
              "Select",
              {
                props: {
                  labelInValue: true,
                  value: row.photoType
                },
                class: {
                  noPass: row.photoType || row.photoType === 0 ? false : true
                },
                on: {
                  "on-change": val => {
                    if (!row.id) {
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        photoType: val.value,
                        photoTypeName: val.label
                      });
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        this.listData[i].id === row.id &&
                          this.listData.splice(i, 1, {
                            ...this.listData[i],
                            photoType: val.value,
                            photoTypeName: val.label
                          });
                      }
                    }
                  }
                }
              },
              this.selOption(this.photoTypeOptions, c)
            );
          }
        },
        {
          title: "抓拍质量",
          key: "photoQualityName",
          minWidth: 110,
          render: (m, { row }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.photoQualityName);
            if (row.deviceType === 6) return <span>--</span>;
            return c(
              "Select",
              {
                props: {
                  labelInValue: true,
                  value: row.photoQuality
                },
                class: {
                  noPass:
                    row.photoQuality || row.photoQuality === 0 ? false : true
                },
                on: {
                  "on-change": val => {
                    if (!row.id) {
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        photoQuality: val.value,
                        photoQualityName: val.label
                      });
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        this.listData[i].id === row.id &&
                          this.listData.splice(i, 1, {
                            ...this.listData[i],
                            photoQuality: val.value,
                            photoQualityName: val.label
                          });
                      }
                    }
                  }
                }
              },
              this.selOption(this.photoQualityOptions, c)
            );
          }
        },
        {
          title: "安装位置",
          key: "remark",
          minWidth: 120,
          render: (m, { row, index }) => {
            let c = this.$createElement;
            if (!row.isE) return c("span", row.remark);
            return c("Input", {
              props: {
                value: row.remark,
                icon: "md-pin"
              },
              ref: "videoRemarkInputRef",
              attrs: {
                disabled: true,
                placeholder: "点击选择位置"
              },
              on: {
                "on-click": e => {
                  this.openPositionModal(row, index);
                },
                "on-change": e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      remark: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id &&
                        this.listData.splice(i, 1, {
                          ...this.listData[i],
                          remark: e.target.value
                        });
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.videoRemarkInputRef.focus();
                  });
                }
              }
            });
          }
        },
        {
          title: "操作",
          key: "action",
          // fixed: 'right',
          minWidth: 110,
          render: (c, { row }) => {
            if (row.isE) {
              return c("div", [
                c("Icon", {
                  attrs: {
                    title: "保存"
                  },
                  style: {
                    marginRight: "15px",
                    cursor: "pointer",
                    color: "#08DDDD",
                    fontSize: "16px"
                  },
                  props: {
                    type: "md-checkmark"
                  },
                  nativeOn: {
                    click: () => {
                      !row.id
                        ? this.saveVideoNew(row)
                        : this.saveVideoEdit(row);
                    }
                  }
                }),
                c("Icon", {
                  attrs: {
                    title: "取消"
                  },
                  style: {
                    cursor: "pointer",
                    color: "#08DDDD",
                    fontSize: "16px"
                  },
                  props: {
                    type: "md-close"
                  },
                  nativeOn: {
                    click: () => {
                      !row.id
                        ? this.cancelVideoNew(row)
                        : this.cancelVideoEdit(row);
                    }
                  }
                })
              ]);
            } else {
              let delBtn = c("Icon", {
                attrs: {
                  title: "删除"
                },
                style: {
                  cursor: "pointer",
                  color: "#08DDDD",
                  fontSize: "16px"
                },
                props: {
                  type: "md-trash"
                }
              });
              return this.deleteLoading && this.deletingId === row.id
                ? c("Spin", {
                    props: {
                      size: "small"
                    }
                  })
                : c("div", [
                    this.accessData.sceneVideoMod &&
                      c("Icon", {
                        attrs: {
                          title: "编辑"
                        },
                        style: {
                          cursor: "pointer",
                          color: "#08DDDD",
                          fontSize: "16px",
                          marginRight: "10px"
                        },
                        props: {
                          type: "ios-brush"
                        },
                        nativeOn: {
                          click: () => {
                            this.videoEdit(row);
                          }
                        }
                      }),
                    this.accessData.sceneVideoPol &&
                      row.deviceType !== 6 &&
                      c("Icon", {
                        attrs: {
                          title: "抓拍策略配置"
                        },
                        style: {
                          cursor: "pointer",
                          color: "#08DDDD",
                          fontSize: "16px",
                          marginRight: "10px"
                        },
                        props: {
                          type: "md-images"
                        },
                        nativeOn: {
                          click: () => {
                            this.videoPhotoSet(row);
                          }
                        }
                      }),
                    this.accessData.sceneVideoDel &&
                      c(
                        "Poptip",
                        {
                          props: {
                            confirm: true,
                            title: "确认删除？",
                            placement: "left"
                          },
                          on: {
                            "on-ok": () => {
                              this.videoDel(row.id);
                            },
                            "on-cancel": () => {}
                          }
                        },
                        [delBtn]
                      )
                  ]);
            }
          }
        }
      ],
      listData: [],
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10
      },
      deviceTypeOptions: [],
      listLoading: false,
      farmOptions: [],
      photoTypeOptions: [],
      photoQualityOptions: [],
      filter: {},
      editData: {},
      deletingId: "",
      deleteLoading: false,
      statusId: "",
      statusLoading: false,
      isPhotoCfgModalShow: false,
      currentVideoObj: {},
      // 地点是否选中
      positionSelected: false,
      // 地点选中信息
      positionX: "",
      positionY: "",
      mapModel: false,
      currentEditRow: null,
      currentEditIndex: -1
    };
  },
  computed: {
    accessData() {
      return {
        // 检测、控制、视频表格权限
        sceneVideoAdd: this.$store.state.app.permissionsGroup.sceneVideoAdd,
        sceneVideoDel: this.$store.state.app.permissionsGroup.sceneVideoDel,
        sceneVideoMod: this.$store.state.app.permissionsGroup.sceneVideoMod,
        sceneVideoStatus: this.$store.state.app.permissionsGroup
          .sceneVideoStatus,
        sceneVideoPol: this.$store.state.app.permissionsGroup.sceneVideoPol
      };
    }
  },
  methods: {
    // 确认选择
    confirmPosition() {
      if (!this.currentEditRow || this.currentEditIndex < 0) return;
      let row = { ...this.currentEditRow };

      row.remark =
        this.positionX && this.positionY
          ? `${this.positionX}:2000,${this.positionY}:1250`
          : "";
      this.listData.splice(this.currentEditIndex, 1, row);
      this.mapModel = false;
    },
    // 图片点击
    positionClick(event) {
      let clientHeight = event.clientY;
      let scrollTop = this.$refs.mapContainer.scrollTop;
      let top = clientHeight - 67 + scrollTop;

      this.positionY = top;

      let clientLeft = event.clientX;
      let scrollLeft = this.$refs.mapContainer.scrollLeft;

      let screenWidth = document.body.clientWidth;

      let width = clientLeft - 16 + scrollLeft;
      this.positionX = width;

      this.positionSelected = true;
    },
    // 地图选择关闭
    mapModalChange(visible) {
      if (visible) return;
      this.positionX = "";
      this.positionY = "";
      this.positionSelected = false;
    },
    // 打开定位弹窗
    openPositionModal(row, index) {
      try {
        let arr = row.remark.split(",");
        this.positionX = arr[0].split(":")[0];
        this.positionY = arr[1].split(":")[0];
        this.positionSelected = true;
      } catch (error) {
        this.positionX = "";
        this.positionY = "";
        this.positionSelected = false;
      }
      this.currentEditRow = row;
      this.currentEditIndex = index;
      this.mapModel = true;
    },
    pcModalClose() {
      this.isPhotoCfgModalShow = false;
    },
    selOption(dataArr, c) {
      var options = [];
      for (var val of dataArr) {
        options.push(
          c(
            "Option",
            {
              props: {
                value: val.value
              }
            },
            val.label
          )
        );
      }
      return options;
    },

    filterChange(field, type, val, selData) {
      if (type === "cas") {
        if (val.length === 1) {
          this.filter = {
            ...this.filter,
            farmId: val[0]
          };
        } else if (val.length === 2) {
          this.filter = {
            ...this.filter,
            farmId: val[0],
            sceneId: val[1]
          };
        } else {
          this.filter = {
            ...this.filter,
            farmId: void 0,
            sceneId: void 0
          };
        }
        this.getList(true);
      } else if (type === "input") {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.filter = {
            ...this.filter,
            [field]: val.target.value ? val.target.value : void 0
          };
          this.getList(true);
        }, 500);
      } else if (type === "select") {
        this.filter = {
          ...this.filter,
          [field]: val ? val.value : val
        };
        this.getList(true);
      } else if (type === "datetimerange") {
        this.filter = {
          ...this.filter,
          beginTime: val[0] ? val[0] : void 0,
          endTime: val[1] ? val[1] : void 0
        };
        this.getList(true);
      }
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList(true);
    },

    getList(loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.RECORD.RECORD_LIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter
      })
        .then(resp => {
          this.listData = resp.list.map(item => {
            return {
              ...item,
              photoType: +item.photoType,
              photoQuality: +item.photoQuality
            };
          });
          this.page = {
            total: +resp.total,
            pageNo: +resp.pageNo,
            totalPage: +resp.totalPage,
            pageSize: +resp.pageSize
          };
          loadingShow && (this.listLoading = false);
        })
        .catch(e => {
          loadingShow && (this.listLoading = false);
        });
    },

    getFarmList() {
      this.$post(this.$api.FARM.LIST, {
        pageNo: 1,
        pageSize: 99999
      }).then(resp => {
        this.farmOptions = resp.list.map(item => ({
          value: +item.farmId,
          label: item.name,
          children: [],
          loading: false
        }));
      });
    },

    getFarmChildren(item, cb) {
      item.loading = true;
      this.$post(this.$api.FARM.SCENE_LIST, {
        farmId: item.value,
        pageSize: 99999,
        pageNo: 1
      })
        .then(resp => {
          item.children = resp.list.map(elem => ({
            value: elem.sceneId,
            label: elem.name
          }));
          item.loading = false;
          cb();
        })
        .catch(e => {
          item.loading = false;
          cb();
        });
    },

    getDeviceType() {
      this.$post(this.$api.COMMON.DICT, {
        name: "设备厂商"
      }).then(resp => {
        this.deviceTypeOptions = resp.map(item => {
          return {
            value: item.num,
            label: item.name
          };
        });
      });
    },

    getPhotoType() {
      this.$post(this.$api.COMMON.DICT, {
        name: "抓拍图片分辨率"
      }).then(resp => {
        this.photoTypeOptions = resp.map(item => {
          return {
            value: item.num,
            label: item.name
          };
        });
      });
    },

    getPhotoQuality() {
      this.$post(this.$api.COMMON.DICT, {
        name: "抓拍图片质量"
      }).then(resp => {
        this.photoQualityOptions = resp.map(item => {
          return {
            value: item.num,
            label: item.name
          };
        });
      });
    },

    videoNew() {
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true
      });
    },

    saveVideoNew(r) {
      let noPass;
      if (r.deviceType == 6) {
        noPass = !r.name || !r.linkIp;
      } else {
        noPass =
          !r.name ||
          !(r.deviceType || r.deviceType === 0) ||
          !r.linkIp ||
          !r.devicePort ||
          !(r.channelNo || +r.channelNo === 0) ||
          !r.loginUser ||
          !r.loginPwd ||
          !(r.photoType || r.photoType === 0) ||
          !(r.photoQuality || r.photoQuality === 0);
      }
      if (noPass) return;
      this.listLoading = true;
      delete r.isE;
      if (r.deviceType === 6) {
        r = {
          ...r,
          photoType: 0,
          photoQuality: 0,
          devicePort: 0
        };
      }
      this.$post(this.$api.RECORD.RECORD_NEW, {
        ...r,
        farmId: this.farmId,
        sceneId: this.sceneId
      })
        .then(resp => {
          this.getList(true);
        })
        .catch(e => {
          this.listLoading = false;
        });
    },

    cancelVideoNew() {
      this.listData.shift();
    },

    videoEdit(record) {
      let listData = this.listData.map(item => {
        return item.id === record.id ? { ...item, isE: true } : item;
      });
      this.listData = listData;
      this.editData = { ...record };
    },

    saveVideoEdit(r) {
      let noPass =
        !r.name ||
        !(r.deviceType || r.deviceType === 0) ||
        !r.linkIp ||
        !r.devicePort ||
        !(r.channelNo || +r.channelNo === 0) ||
        !r.loginUser ||
        !r.loginPwd ||
        !(r.photoType || r.photoType === 0) ||
        !(r.photoQuality || r.photoQuality === 0);
      if (noPass) return;
      this.listLoading = true;
      delete r.isE;
      this.$post(this.$api.RECORD.RECORD_EDIT, {
        ...r,
        farmId: this.farmId,
        sceneId: this.sceneId,
        id: r.id
      })
        .then(resp => {
          this.getList(true);
        })
        .catch(e => {
          this.listLoading = false;
        });
    },

    cancelVideoEdit(record) {
      this.listData = this.listData.map(item => {
        return item.id === record.id ? this.editData : item;
      });
    },

    videoDel(id) {
      this.deletingId = id;
      this.deleteLoading = true;
      this.$post(this.$api.RECORD.RECORD_DEL, {
        id
      })
        .then(resp => {
          this.deleteLoading = false;
          this.getList(true);
        })
        .catch(e => {
          this.deleteLoading = false;
        });
    },

    statusChange(status, id) {
      this.statusLoading = true;
      this.statusId = id;
      this.$post(this.$api.RECORD.RECORD_Status, {
        status,
        id
      })
        .then(resp => {
          this.statusLoading = false;
          this.getList();
        })
        .catch(e => {
          this.statusLoading = false;
        });
    },

    videoPhotoSet({ name, id }) {
      this.isPhotoCfgModalShow = true;
      this.currentVideoObj = { name, id };
    }
  },
  mounted() {
    this.getFarmList();
    this.getList(true);
    this.getDeviceType();
    this.getPhotoType();
    this.getPhotoQuality();
  }
};
</script>
<style lang="less">
.svideo {
  .videoGrid {
    // margin-top: 15px;
  }
  .noPass {
    input {
      border: 1px solid #e4393c;
    }
    .ivu-select-selection {
      border: 1px solid #e4393c;
    }
    .ivu-input-number {
      border: 1px solid #e4393c;
      input {
        border: none;
      }
    }
  }
}
</style>


